import * as PIXI from 'pixi.js';
import TweenMax, { Linear } from 'gsap';
import GameModel from '../models/GameModel';
import eGameState from '../models/eGameState';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import ImagePreloader from '../preloaders/images/ImagePreloader';
import eImageType from '../preloaders/images/eImageType';

export default class ControllerProgress {
  constructor(container) {
    this.container = new PIXI.Container();
    this.container.visible = false;

    this._buildProgress(this.container);

    if (GameModel.state === eGameState.EGS_RESULTS) {
      this.show();
    }

    container.addChild(this.container);

    GlobalDispatcher.add(eWSEventId.EWEI_START, this.hide, this);

  }

  show() {
    this.container.visible = true;
    this.animateProgress();
  }

  hide() {
    this.container.visible = false;
    TweenMax.killTweensOf(this.mask);
    this.mask.x = -500;
  }

  _buildProgress(container) {
    const progressBg = ImagePreloader.createImage(eImageType.EIT_PROGRESS_BG);
    const x = 3145;
    const y = 1750;
    progressBg.anchor.set(0.5);
    progressBg.x = x;
    progressBg.y = y;
    const progress = ImagePreloader.createImage(eImageType.EIT_PROGRESS);
    progress.anchor.set(0.5);
    progress.x = x;
    progress.y = y;

    this.mask = new PIXI.Graphics();
    this.mask.beginFill('0x000000');
    this.mask.drawRect(x - progressBg.width / 2, y - 6, progressBg.width, progressBg.height);

    progress.mask = this.mask;
    this.mask.x -= 500;

    container.addChild(progressBg);
    container.addChild(progress);
    container.addChild(this.mask);
  }

  animateProgress() {
    TweenMax.killTweensOf(this.mask);
    const duration = (GameModel.nextRoundIn - new Date().getTime() + GameModel.serverTimeOffset) / 1000;
    TweenMax.to(this.mask, duration, { x: 0, ease: Linear.easeNone });
  }
}
