export default class ControllerMiniPreloader {
  constructor() {
    this.element = document.getElementById('miniPreloader');
    window.miniPreloader = this;
  }

  hide() {
    this.element.style.setProperty('display', 'none');
  }

  show() {
    this.element.style.setProperty('display', 'flex');
  }
}
