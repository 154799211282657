import eSmileType from '../preloaders/images/eSmileType';
import ImagePreloader from '../preloaders/images/ImagePreloader';
import { sendEmoji } from '../api/wsRequests';

export default class ControllerSmilesPanel {
  constructor() {
    this.container = document.getElementById('smilesPanel');
    this.buttonToggle = this.container.getElementsByClassName('smile_picker__trigger')[0].querySelector('button');
    this.slider = document.getElementById('smilesSlider');

    this.buttonToggle.addEventListener('click', this.onSliderToggle.bind(this));
    this.opened = false;

    for (const key in eSmileType) {
      const type = eSmileType[key];
      const image = document.createElement('img');
      image.setAttribute('src', ImagePreloader.getSrc(type));
      image.setAttribute('alt', type + ' smile');

      const element = document.createElement('button');
      element.appendChild(image);
      element.addEventListener('click', () => this.onSmileClicked(type));
      this.slider.prepend(element);
    }
  }

  onSmileClicked(type) {
    sendEmoji(type);
  }

  onSliderToggle() {
    this.opened = !this.opened;

    this.slider.classList[this.opened ? 'add' : 'remove']('open');
    this.buttonToggle.innerHTML = this.opened ? this.getCloseIcon() : this.getSmileIcon();
  }

  getSmileIcon() {
    return `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.9499 36.6663C29.1546 36.6663 36.6165 29.2044 36.6165 19.9997C36.6165 10.7949 29.1546 3.33301 19.9499 3.33301C10.7451 3.33301 3.2832 10.7949 3.2832 19.9997C3.2832 29.2044 10.7451 36.6663 19.9499 36.6663Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M12.0166 25C13.7666 27.5167 16.6999 29.1667 19.9999 29.1667C23.2999 29.1667 26.2166 27.5167 27.9833 25"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M25.8333 17.917C27.214 17.917 28.3333 16.7977 28.3333 15.417C28.3333 14.0363 27.214 12.917 25.8333 12.917C24.4525 12.917 23.3333 14.0363 23.3333 15.417C23.3333 16.7977 24.4525 17.917 25.8333 17.917Z"
                stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M14.1667 17.917C15.5475 17.917 16.6667 16.7977 16.6667 15.417C16.6667 14.0363 15.5475 12.917 14.1667 12.917C12.786 12.917 11.6667 14.0363 11.6667 15.417C11.6667 16.7977 12.786 17.917 14.1667 17.917Z"
                stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                stroke-linejoin="round" />
          </svg>`;
  }

  getCloseIcon() {
    return `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 35L35 5" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M35 35L5 5" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`;
  }
}
