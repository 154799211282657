import BaseValueContainer, { eButtonType } from './BaseValueContainer';

export default class CoefContainer extends BaseValueContainer {
  constructor(element, model) {
    super(element, model);
    this.amountInput.value = this.model.formatTakeCoef;
    this.autoCheckbox.checked = this.model.autoTake;

    this.autoCheckbox.disabled = this._disableCondition();
  }

  onInputBlur(event) {
    const value = parseFloat(event.target.value);
    if (value) {
      this.model.takeCoef = value;
    } else {
      this.amountInput.value = this.model.formatTakeCoef;
    }
  }

  onCheckboxChanged(event) {
    super.onCheckboxChanged(event);
    this.model.autoTake = event.target.checked;
  }

  onPlusClicked() {
    super.onPlusClicked();
    this.model.increaseTakeCoef();
  }

  onMinusClicked() {
    super.onMinusClicked();
    this.model.decreaseTakeCoef();
  }

  onButtonPressed(type) {
    if (type === eButtonType.EBT_MINUS) {
      this.model.decreaseTakeCoef();
    } else {
      this.model.increaseTakeCoef();
    }
  }

  onModelChanged() {
    super.onModelChanged();
    this.autoCheckbox.disabled = this._disableCondition();
    this.amountInput.value = this.model.formatTakeCoef;
  }

  onLocalizationChanged() {
    super.onLocalizationChanged();
    this.element.getElementsByClassName('rates-setting__title')[0].textContent = window.OPWrapperService.localizations.getLocalizedText('take_coef');
  }
}
