import GlobalDispatcher from '../../events/GlobalDispatcher';
import eWSEventId from '../../api/eWSEventId';
import AnimationLoader from '../../preloaders/animations/AnimationLoader';
import eAnimationType from '../../preloaders/animations/eAnimationType';

export default class ControllerFlags {
  constructor(groundContainer) {
    this._animationPool = [];
    this.flags = [];
    this.groundContainer = groundContainer;
    GlobalDispatcher.add(eWSEventId.EWEI_TAKE, this.spawnFlag, this);
  }

  spawnFlag(data) {
    if (data.params.code) return; //if error don't spawn
    const flagFromPool = this._animationPool.pop();
    const flag = flagFromPool || AnimationLoader.createAnimation(eAnimationType.EAT_PARACHUTES);
    if (!flagFromPool) {
      flag.state.addListener({
        complete: () => {
          flag.visible = false;
          this._animationPool.push(flag);
        }
      });
    }
    const animationName = data.params.requestId ? 'DEW_parachute_eject_player' : 'DEW_parachute_eject_other_users';

    flag.state.setAnimation(0, animationName, false);
    flag.alpha = data.params.requestId ? 1 : 0.3;
    flag.visible = true;

    const container = new PIXI.Container();
    container.addChild(flag);

    const jetPosition = this.groundContainer.toLocal({ x: 0 , y: 0 }, window.jetBodyContainer);//TODO: this is bad solution
    container.y = 1880;
    container.x = jetPosition.x + 56;
    this.groundContainer.addChild(container);
    this.flags.push(container);
  }

  reset() {
    for (const item of this._animationPool) {
      item.destroy({ children: true });
    }
    this._animationPool = [];

    for (const item of this.flags) {
      item.destroy({ children: true });
    }
    this.flags = [];
  }
}
