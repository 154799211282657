import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import BetModel from './BetModel';
import Event from '../utils/event';
import eGameState from './eGameState';
import { formatCurrency } from '../utils/currency';
import MessageAdapter from '../messageAdapter/MessageAdapter';

export default new class GameModel {
  constructor() {
    this._balance = 100000000;
    this._jackpot = 0;
    this._bets = [];
    this._currency = 'DEMO';
    this._decimal = 2;
    this.coef = 0;
    this.maxBet = 200;
    this.state = eGameState.EGS_START;

    this.minBetAmount = 0.5;

    this.onBetsChanged = new Event();
    this.onBalanceChanged = new Event();
    this.onJackpotChanged = new Event();

    GlobalDispatcher.add(eWSEventId.EWEI_INFO, this._onInfo, this);
    GlobalDispatcher.add(eWSEventId.EWEI_AUTH, this._onAuth, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
    GlobalDispatcher.add(eWSEventId.EWEI_START, this._onStart, this);
    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this._onProgress, this);
    GlobalDispatcher.add(eWSEventId.EWEI_BALANCE, this._onBalanceChanged, this);
    GlobalDispatcher.add(eWSEventId.EWEI_BET, this._onBet, this);
    GlobalDispatcher.add(eWSEventId.EWEI_CANCEL, this._onCancel, this);
    GlobalDispatcher.add(eWSEventId.EWEI_JACKPOT_AMOUNT, this._onJackpotAmountChanged, this);
    GlobalDispatcher.add(eWSEventId.EWEI_DESTROY_CRATE, (data) => this.crate = null);
    GlobalDispatcher.add(eWSEventId.EWEI_SPAWN_CRATE, (data) => this.crate = { id: 'spawnCrate', x: 0 });
  }

  _onInfo({ params }) {
    this.serverTimeOffset = Date.now() - params._t;
  }

  _onAuth(data) {
    const info = data.params;
    this._currency = info.currency;
    this._decimal = info.decimal || info.decimals;
    this.nextRoundIn = info.nextIn;
    this.state = info.state;
    this.currentX = info.currentX;
    if (info.state !== 'RESULTS') this.roundID = info.currentRoundId;
    this.balance = info.balance;
    this.jackpot = info.jackpot;
    let myBets = [...info.myBets];
    this.maxBet = info.maxBet;
    this.minBetAmount = info.availableBets[0];
    this.availableBets = info.availableBets;
    let bets = [...this._bets];
    myBets = myBets.filter(item => !item.isTaken);

    // update current bets except waiting send and exclude from arrays
    myBets = myBets.filter(myBet => {
      const bet = bets.find(bet => bet.betID === myBet.betId);
      if (bet) {
        bets = bets.filter(item => item !== bet);
        if (!bet.waitingAuth) {
          bet.setData(myBet);
          bet.waitingForResponse = false;
        }
        return false;
      }
      return true;
    });

    // exclude bets waiting send from arrays
    bets = bets.filter(bet => !bet.waitingAuth);

    // clear other bets
    bets.forEach(bet => {
      bet.setBetInfo(null, null);
      bet.waitingForResponse = false;
    });

    // add new bets
    for (let i = 0; i < myBets.length; i++) {
      const myBet = myBets[i];
      let betModel = new BetModel();
      this.addBets(betModel);
      betModel.setData(myBet);
      betModel.waitingForResponse = false;
    }

    if (!this._bets.length) this._bets.push(new BetModel());

    if (info.crate) {
      this.crate = info.crate;
    }
  }

  _onStart(data) {
    this.roundID = data.params.roundId;
    this.state = eGameState.EGS_START;
  }

  _onProgress(data) {
    this.state = eGameState.EGS_PROGRESS;
    this.coef = data.params.coef;
    this.currentX = data.params.x;
  }

  _onFinish(data) {
    this.nextRoundIn = data.params.nextIn;
    this.state = eGameState.EGS_RESULTS;

    if (data.params.reason === 'jackpot') {
      this._bets.forEach(betModel => {
        const betData = data.params.awards.find(item => item.betId === betModel.betID);
        if (betData) {
          OPWrapperService.ControllerStatistic.win = betData.amount * data.params.coef + betData.jackpot_total * betData.jackpot_percent;
        }
      })
    }

    for (const item of this._bets) {
      if (this.roundID !== item.roundID) continue;
      item.setBetInfo(null, null, window.OPWrapperService.freeBetsController.isFreeBets && window.OPWrapperService.freeBetsController.data.bet);
    }
    if (!this.bets.find(betModel => !!betModel.betID)) OPWrapperService.realityCheck.blockedRealityCheck = false;
  }

  _onBet(data) {
    if (data.params.requestId && typeof(data.params.balance) === 'number')
      this.balance = data.params.balance;
  }

  _onCancel(data) {
    if (typeof(data.params.balance) === 'number')
      this.balance = data.params.balance;
  }

  _onBalanceChanged(data) {
    this.balance = data.params.balance;
    MessageAdapter.trySendRoundEnd();
  }

  _onJackpotAmountChanged(data) {
    if (data.params.hasOwnProperty('jackpot'))
      this.jackpot = data.params.jackpot;
  }

  get formatBalance() {
    return `${formatCurrency(this._balance, this._decimal, false)} ${this._currency}`;
  }

  get formatJackpot() {
    return `${formatCurrency(this._jackpot, this._decimal, false)} ${this._currency}`;
  }

  get balance() {
    return this._balance;
  }

  set balance(value) {
    this._balance = value;
    this.onBalanceChanged.call();
  }

  get jackpot() {
    return this._jackpot;
  }

  set jackpot(value) {
    this._jackpot = value;
    this.onJackpotChanged.call();
  }

  get bets() {
    return this._bets;
  }

  get currency() {
    return this._currency;
  }

  get decimal() {
    return this._decimal;
  }

  addBets(item) {
    this._bets.push(item);
    if (OPWrapperService.config.isMaxBetsCountOne && this._bets.length > 1) item.blocked = true;
    this.onBetsChanged.call();
  }

  removeBet(item) {
    const index = this._bets.indexOf(item);
    this._bets[index].onDelete();
    this._bets.splice(index, 1);
    this.onBetsChanged.call();
  }

  updateBets(index, data) {
    this._bets[index] = data;
  }
}
