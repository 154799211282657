import ControllerMovingBGBase from './ControllerMovingBGBase';
import AnimationLoader from '../../preloaders/animations/AnimationLoader';
import eAnimationType from '../../preloaders/animations/eAnimationType';
import { getRandomInt } from '../../utils/random';

export default class ControllerBgJets extends ControllerMovingBGBase {
  constructor(container) {
    super(container);

    this._jetFights = [];
    this.stepDevider = 0.2;
  }

  onUpdate() {
    super.onUpdate();
    if (this.container.x > -1000) return;
    if (getRandomInt(1500) !== 42) return;
    this._trySpawnJetFight();
  }

  _trySpawnJetFight() {
    const lastAnimation = this._jetFights[this._jetFights.length - 1];
    const hasSpace = !lastAnimation || !lastAnimation.isPlaying;

    if (!hasSpace) return;

    const animation = AnimationLoader.createAnimation(eAnimationType.EAT_BG_JETS);

    const rand = getRandomInt(2);
    const animationName = `dog_fight_${1 + rand}`;
    switch (rand) {
      case 0:
        animation.x = -this.container.x + 3800 + 500;
        break;
      case 1:
      case 2:
        animation.x = -this.container.x + 2800 + 1615;
        break;
      default:
        console.warn('Unexpected random value: ', rand);
        break;
    }
    animation.y = 1600 + getRandomInt(400);
    animation.state.setAnimation(0, animationName, true);
    animation.isPlaying = true;
    animation.state.addListener({
      complete: () => {
        animation.destroy();
        animation.isPlaying = false;
        this._jetFights.remove(animation);
      }
    });
    this.container.addChild(animation);
    this._jetFights.push(animation);
  }

  reset() {
    super.reset();

    for (const item of this._jetFights) {
      try {
        TweenMax.killTweensOf(item);
        item.destroy();
        item.isPlaying = false;
      } catch (e) {
        console.warn(e);
      }
    }
    this._jetFights = [];
  }
}
