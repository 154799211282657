import progress from '../../../assets/images/progress.webp';
import progressBg from '../../../assets/images/progress_bg.webp';

import sky_0 from '../../../assets/images/bg/sky_0.webp';
import sky_1 from '../../../assets/images/bg/sky_1.webp';
import sky_2 from '../../../assets/images/bg/sky_2.webp';
import water from '../../../assets/images/bg/water.webp';
import bg_fighter0 from '../../../assets/images/bg/bg_fighter0.webp';
import bg_fighter1 from '../../../assets/images/bg/bg_fighter1.webp';
import bg_ship0 from '../../../assets/images/bg/bg_ship0.webp';
import bg_ship1 from '../../../assets/images/bg/bg_ship1.webp';
import cloud from '../../../assets/images/bg/cloud.webp';
import dusk_mountain0 from '../../../assets/images/bg/dusk_mountain0.webp';
import dusk_mountain1 from '../../../assets/images/bg/dusk_mountain1.webp';
import dusk_mountain2 from '../../../assets/images/bg/dusk_mountain2.webp';
import icy_mountain0 from '../../../assets/images/bg/icy_mountain0.webp';
import icy_mountain1 from '../../../assets/images/bg/icy_mountain1.webp';
import icy_mountain2 from '../../../assets/images/bg/icy_mountain2.webp';
import sand_mountains from '../../../assets/images/bg/sand_mountains.webp';
import ship_bow from '../../../assets/images/bg/ship_bow.webp';
import ship_stern from '../../../assets/images/bg/ship_stern.webp';
import jackpotBG from '../../../assets/images/jackpot/bg.webp';
import jackpotBGNotWin from '../../../assets/images/jackpot/not_win.webp';

import beer from '../../../assets/images/smiles/beer.webp';
import fire from '../../../assets/images/smiles/fire.webp';
import flushed from '../../../assets/images/smiles/flushed.webp';
import folded_hands from '../../../assets/images/smiles/folded_hands.webp';
import four_leaf_clover from '../../../assets/images/smiles/four_leaf_clover.webp';
import money_mouth from '../../../assets/images/smiles/money_mouth.webp';
import pile_of_poo from '../../../assets/images/smiles/pile_of_poo.webp';
import screaming_in_fear from '../../../assets/images/smiles/screaming_in_fear.webp';
import slot_machine from '../../../assets/images/smiles/slot_machine.webp';
import sunglasses from '../../../assets/images/smiles/sunglasses.webp';
import swearing from '../../../assets/images/smiles/swearing.webp';
import tongue from '../../../assets/images/smiles/tongue.webp';

import openSmiles from '../../../assets/images/smiles/button_open.webp';
import closeSmiles from '../../../assets/images/smiles/button_close.webp';

import eImageType from './eImageType';
import * as PIXI from 'pixi.js';
import eSmileType from './eSmileType';

export default new class ImagePreloader {
  constructor() {
    this.imagesData = [
      { type: eImageType.EIT_PROGRESS, src: progress },
      { type: eImageType.EIT_PROGRESS_BG, src: progressBg },

      { type: eImageType.EIT_SKY_0, src: sky_0 },
      { type: eImageType.EIT_SKY_1, src: sky_1 },
      { type: eImageType.EIT_SKY_2, src: sky_2 },
      { type: eImageType.EIT_WATER, src: water },
      { type: eImageType.EIT_BG_FIGHTER_0, src: bg_fighter0 },
      { type: eImageType.EIT_BG_FIGHTER_1, src: bg_fighter1 },
      { type: eImageType.EIT_BG_SHIP_0, src: bg_ship0 },
      { type: eImageType.EIT_BG_SHIP_1, src: bg_ship1 },
      { type: eImageType.EIT_CLOUD, src: cloud },
      { type: eImageType.EIT_DUSK_MOUNTAIN_0, src: dusk_mountain0 },
      { type: eImageType.EIT_DUSK_MOUNTAIN_1, src: dusk_mountain1 },
      { type: eImageType.EIT_DUSK_MOUNTAIN_2, src: dusk_mountain2 },
      { type: eImageType.EIT_ICY_MOUNTAIN_0, src: icy_mountain0 },
      { type: eImageType.EIT_ICY_MOUNTAIN_1, src: icy_mountain1 },
      { type: eImageType.EIT_ICY_MOUNTAIN_2, src: icy_mountain2 },
      { type: eImageType.EIT_SAND_MOUNTAIN_2, src: sand_mountains },
      { type: eImageType.EIT_SHIP_BOW, src: ship_bow },
      { type: eImageType.EIT_SHIP_STERN, src: ship_stern },
      { type: eImageType.EIT_JACKPOT_BG, src: jackpotBG },
      { type: eImageType.EIT_JACKPOT_BG_NOT_WIN, src: jackpotBGNotWin },

      { type: eSmileType.EST_BEER, src: beer },
      { type: eSmileType.EST_FIRE, src: fire },
      { type: eSmileType.EST_FLUSHED, src: flushed },
      { type: eSmileType.EST_FOLDED_HANDS, src: folded_hands },
      { type: eSmileType.EST_FOUR_LEAF_CLOVER, src: four_leaf_clover },
      { type: eSmileType.EST_MONEY_MOUTH, src: money_mouth },
      { type: eSmileType.EST_PILE_OF_POO, src: pile_of_poo },
      { type: eSmileType.EST_SCREAMING_IN_FEAR, src: screaming_in_fear },
      { type: eSmileType.EST_SLOT_MACHINE, src: slot_machine },
      { type: eSmileType.EST_SUNGLASSES, src: sunglasses },
      { type: eSmileType.EST_SWEARING, src: swearing },
      { type: eSmileType.EST_TONGUE, src: tongue },

      { type: eImageType.EIT_OPEN_SMILES, src: openSmiles },
      { type: eImageType.EIT_CLOSE_SMILES, src: closeSmiles },
    ];
  }

  addAssetsToBundle(bundleAssets) {
    for (let asset of this.imagesData) {
      bundleAssets[asset.type] = asset.src;
    }
  }

  createImage(type) {
    return new PIXI.Sprite(this.getTexture(type));
  }

  getTexture(type) {
    return PIXI.Assets.get(type);
  }

  getSrc(type) {
    const index = this.imagesData.findIndex((item) => item.type === type);
    if(index === -1){
      console.error(`Unlisted image type: ${type}`)
    }
    return this.imagesData[index].src;
  }
}
