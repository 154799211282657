import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import SmileView from './SmileView';

export default class ControllerSmiles {
  constructor(container) {
    this.container = container;
    GlobalDispatcher.add(eWSEventId.EWEI_EMOJI, this._onSmileReceived, this);
  }

  _onSmileReceived(data) {
    // new SmileView(this.container, 3560, 2050, data.params.emojiId, data.params.clientId);
    // for (let i = 0; i < 500; i++) {
    if(document.hidden) return;
    new SmileView(this.container, 880, 650, data.params.emojiId, data.params.clientId);
    // }
  }
}
