export default {
  EIT_PROGRESS: 'progress',
  EIT_PROGRESS_BG: 'progressBg',

  EIT_SKY_0: 'sky_0',
  EIT_SKY_1: 'sky_1',
  EIT_SKY_2: 'sky_2',
  EIT_WATER: 'water',
  EIT_BG_FIGHTER_0: 'bg_fighter0',
  EIT_BG_FIGHTER_1: 'bg_fighter1',
  EIT_BG_SHIP_0: 'bg_ship0',
  EIT_BG_SHIP_1: 'bg_ship1',
  EIT_CLOUD: 'cloud',
  EIT_DUSK_MOUNTAIN_0: 'dusk_mountain0',
  EIT_DUSK_MOUNTAIN_1: 'dusk_mountain1',
  EIT_DUSK_MOUNTAIN_2: 'dusk_mountain2',
  EIT_ICY_MOUNTAIN_0: 'icy_mountain0',
  EIT_ICY_MOUNTAIN_1: 'icy_mountain1',
  EIT_ICY_MOUNTAIN_2: 'icy_mountain2',
  EIT_SAND_MOUNTAIN_2: 'sand_mountains',
  EIT_SHIP_BOW: 'ship_bow',
  EIT_SHIP_STERN: 'ship_stern',

  EIT_JACKPOT_BG: 'jackpotBG',
  EIT_JACKPOT_BG_NOT_WIN: 'jackpotBGMissed',

  EIT_OPEN_SMILES: 'open_smiles',
  EIT_CLOSE_SMILES: 'close_smiles',

  //branding
  EIT_WATERMARK: 'watermark',
}
