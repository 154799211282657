import Event from '../../utils/event';
import AdaptiveController from '../adaptiveController';
import GameScaleManager from '../../GameScaleManager';

export default class NumberInput extends AdaptiveController {
  constructor(element) {
    super();
    this.element = element;
    this.element.addEventListener('blur', this.onInputBlur.bind(this));
    this.element.addEventListener('focus', this.onInputFocus.bind(this));
    this.element.addEventListener('keydown', this.onInput.bind(this));
    this.onBlur = new Event();

    this.adaptiveElement = this.element;
  }

  set value(value) {
    this.element.value = value;
  }

  set readOnly(value) {
    this.element.readOnly = value;
    this.element.disabled = value;
  }

  onInput(event) {
    event.stopPropagation();
    if (!(/\d|\.|,|Backspace/i).test(event.key)) {
      event.preventDefault();
    }
  }

  onInputFocus() {
    this.element.value = '';
    GameScaleManager.resizeBlocked = true;
  }

  onInputBlur(event) {
    event.target.value = event.target.value.replace(',', '.');
    this.onBlur.call(event);
    GameScaleManager.resizeBlocked = false;
  }
}
