export default {
  EST_BEER : 'beer',
  EST_FIRE: 'fire',
  EST_FLUSHED: 'flushed',
  EST_FOLDED_HANDS: 'folded_hands',
  EST_FOUR_LEAF_CLOVER: 'four_leaf_clover',
  EST_MONEY_MOUTH: 'money_mouth',
  EST_PILE_OF_POO: 'pile_of_poo',
  EST_SCREAMING_IN_FEAR: 'screaming_in_fear',
  EST_SLOT_MACHINE: 'slot_machine',
  EST_SUNGLASSES: 'sunglasses',
  EST_SWEARING: 'swearing',
  EST_TONGUE: 'tongue',
}
