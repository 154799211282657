import ControllerMovingBGBase from './ControllerMovingBGBase';
import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import { getRandomInt } from '../../utils/random';

export default class ControllerClouds extends ControllerMovingBGBase {
  constructor(container) {
    super(container);

    this.stepDevider = 4;
    this.clouds = [];
    this._createCloud(3100, 1600);
    this._createCloud(2300, 1500);
    this._createCloud(1100, 1200);
    this._createCloud(3500, 1100);
  }

  onUpdate() {
    super.onUpdate();
    this._trySpawnCloud();
  }

  _trySpawnCloud() {
    const spawnX = -this.container.x + 3600;
    const lastCloud = this.clouds[this.clouds.length - 1];
    const hasSpace = !lastCloud || spawnX > (lastCloud.x + lastCloud.width);
    if (!hasSpace) return;

    const cloud = this._createCloud(spawnX, 1300 + getRandomInt(500));
    this.container.addChild(cloud);
    this.clouds.push(cloud);
  }

  reset() {
    super.reset();
    for (const item of this.clouds) {
      item.destroy();
    }
    this.clouds = [];
  }

  _createCloud(x, y) {
    const cloud = ImagePreloader.createImage(eImageType.EIT_CLOUD);
    cloud.x = x;
    cloud.y = y;
    this.container.addChild(cloud);
    return cloud;
  }
}
