import en from '../assets/i18n/en';
import enSocial from '../assets/i18n/en-social';

export default {
  bundle: 'fighter',
  localizationBundle: 'fighter',
  clock: { enabled: true },
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
};
