import BetContainer from './BetContainer';
import CoefContainer from './CoefContainer';
import ButtonBet from './ButtonBet';
import GameModel from '../../models/GameModel';
import BetModel from '../../models/BetModel';
import SoundManager from '../../soundManager/SoundManager';
import eSoundsType from '../../preloaders/sounds/eSoundsType';
import { formatCurrency } from '../../utils/currency';
import AdaptiveController from '../adaptiveController';

export default class BetBlock {
  constructor(id, model) {
    this.id = id;
    this.model = model;
    this.model.onChange.add(this.onGameModelChanged.bind(this));
    this.template = document.getElementById('bet_block_template');
    this.element = this.template.cloneNode(true);
    this.element.setAttribute('id', `bet_block_${this.id}`);
    this.template.parentNode.appendChild(this.element);

    this.buttonAddBet = this.element.getElementsByClassName('button_add_bet')[0];
    this.buttonRemoveBet = this.buttonAddBet.cloneNode(true);
    this.buttonRemoveBet.innerHTML = '<svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"><path d="m12 36 24-24"/><path d="m36 36-24-24"/></g></svg>';
    this.buttonRemoveBet.addEventListener('click', this.onRemoveBetClicked.bind(this));
    this.buttonAddBet.addEventListener('click', this.onAddBetClicked.bind(this));
    this.updateAddBetButton();
    GameModel.onBetsChanged.add(this.updateAddBetButton.bind(this));

    const predefinedBets = this.element.getElementsByClassName('bet_block__item__predefined_bets')[0];
    this.predifinedButtons = [];
    const buttons = predefinedBets.querySelectorAll('button');
    for (let i = 0; i < 4; i++) {
      const button = buttons[i];
      const amount = this.model.betsButtons[i];
      const adaptiveController = new AdaptiveController();
      adaptiveController.adaptiveElement = button;
      button.addEventListener('click', () => this.onPredefinedBetClicked(amount));
      button.disabled = this.model.choiceBlocked || window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isActive;
      button.innerText = `${formatCurrency(amount, GameModel.decimal)} ${GameModel.currency}`;
      if (GameModel.decimal > 2) button.setAttribute('data-font-size', 'small');
      this.predifinedButtons.push(button);
    }

    this.betContainer = new BetContainer(this.element.getElementsByClassName('bet_block__item__bet')[0], model);
    this.coefContainer = new CoefContainer(this.element.getElementsByClassName('bet_block__item__take')[0], model);
    this.buttonBet = new ButtonBet(this.element.getElementsByClassName('button_bet')[0], document.getElementById('tooltip'), model);
  }

  onGameModelChanged() {
    for (const item of this.predifinedButtons) {
      item.disabled = this.model.choiceBlocked || window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isActive || window.OPWrapperService.freeBetsController.data.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;
    }
    this.buttonRemoveBet.disabled = this.model.choiceBlocked;
  }

  updateAddBetButton() {
    if (GameModel.bets.length > 1) {
      this.buttonAddBet.replaceWith(this.buttonRemoveBet);
      this.buttonRemoveBet.disabled = this.model.choiceBlocked;
    } else {
      this.buttonRemoveBet.replaceWith(this.buttonAddBet);
      this.buttonAddBet.disabled = window.OPWrapperService.config.isMaxBetsCountOne;
    }
  }

  onAddBetClicked() {
    SoundManager.play(eSoundsType.EST_CLICK, 1 + 2);
    GameModel.addBets(new BetModel());
    this.updateAddBetButton();
  }

  onRemoveBetClicked() {
    SoundManager.play(eSoundsType.EST_CLICK, 1 + 2);
    if (this.model.roundID) {
      console.error('Can\'t delete this bet');
      return;
    }
    GameModel.removeBet(this.model);
    this.updateAddBetButton();
  }

  onPredefinedBetClicked(amount) {
    SoundManager.play(eSoundsType.EST_CLICK, 1 + 2);
    this.model.betAmount = amount;
  }

  destroy() {
    this.buttonBet.destroy();
    this.betContainer.destroy();
    this.coefContainer.destroy();
    this.element.parentNode.removeChild(this.element);
  }
}
