import { getUrlParam } from '../js/libs/op-wrapper-service/utils/url';
import eBrandId from './818/eBrandId';
import './branding.css';
import eAnimationType from '../js/preloaders/animations/eAnimationType';
import eImageType from '../js/preloaders/images/eImageType';
import ImagePreloader from '../js/preloaders/images/ImagePreloader';
import { allPreloaders } from '../js/libs/op-preloader/branding/brendingPreloaders';

export const assetsBranding =  (bundleAssets) => {
  if (getUrlParam('pid') === eBrandId.EBI_818) {
    bundleAssets[eAnimationType.EAT_FIGHTER] = 'branding/818/assets/animations/fighter/fighter.json'
    bundleAssets[eImageType.EIT_WATERMARK] = 'branding/818/assets/images/watermark.webp';
  }
}

export const getBrandingPreloader = () => {
  return allPreloaders[getUrlParam('pid')];
}

export const addWatermarkForBranding =  (bgContainer) => {
  if (getUrlParam('pid') === eBrandId.EBI_818) {
    const watermarkContainer = new PIXI.Container();
    watermarkContainer.x = 0;
    watermarkContainer.y = 30;
    const offset = 20;
    const  xCount = 15;
    const  yCount = 16;

    for (let i = 0; i < xCount; i++) {
      for (let j = 0; j < yCount; j++) {
        const watermark = ImagePreloader.createImage(eImageType.EIT_WATERMARK);

        watermark.scale.set(0.50);
        watermark.x = offset + i * (offset + watermark.width);
        watermark.y = offset / 2 + j * (offset + watermark.height);
        watermarkContainer.addChild(watermark);
      }
    }
    bgContainer.addChild(watermarkContainer);
  }
}
