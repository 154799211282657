import ImagePreloader from '../../preloaders/images/ImagePreloader';
import { getRandomInt } from '../../utils/random';
import ControllerMovingBGBase from './ControllerMovingBGBase';

import { clustersInfo } from './clustersInfo';

export default class ControllerBGObjects extends ControllerMovingBGBase {
  constructor(mainContainer) {
    super(mainContainer);
    this.stepDevider = 1;

    this._ships = [];
  }

  onUpdate() {
    if (this.container.x > -500) return;
    this._trySpawnNewCluster();
    this._tryDespawnClusters();
  }

  reset() {
    super.reset();
    for (const item of this._ships) {
      item.container.destroy();
    }
    this._ships = [];
  }

  _trySpawnNewCluster() {
    const lastShip = this._ships[this._ships.length - 1];
    const hasSpace = !lastShip || lastShip.x < this.container.x;
    if (!hasSpace) return;
    if (getRandomInt(600) === 42) {
      const itemInfo = clustersInfo[getRandomInt(clustersInfo.length - 1)];
      this._spawnTempCluster(itemInfo, -this.container.x + 3520);
    }
  }

  _tryDespawnClusters() {
    for (let i = 0; i < this._ships.length; i++) {
      const cluster = this._ships[i].container;
      if (-cluster.x - cluster.width > this.container.x) {
        this._ships.splice(i, 1);
        cluster.destroy({ children: true });
      }
    }
  }
  _spawnTempCluster(itemInfo, x) {
    this._ships.push(this._spawnCluster(itemInfo, x));
  }

  _spawnCluster(itemInfo, x) {
    const container = new PIXI.Container();
    for (let j = 0; j < itemInfo.obj.length; j++) {
      const item = itemInfo.obj[j];
      const sprite = ImagePreloader.createImage(item.image);
      sprite.x = item.x;
      sprite.y = item.image.includes('fighter') ? item.y + Math.random() * 40 : item.y;
      sprite.scale.set(item.scale || 1);
      sprite.scale.x = item.scaleX || 1;
      container.addChild(sprite);
    }
    container.y = 1800;
    container.x = x;
    this.container.addChild(container);
    return { container };
  }
}
