import ManagerResult from '../ManagerResult';
import StatModel from '../models/StatModel';
import SoundManager from '../soundManager/SoundManager';
import eSoundsType from '../preloaders/sounds/eSoundsType';
import { formatCurrency } from '../utils/currency';
import GameModel from '../models/GameModel';

const eScoreType = {
  EST_RARE: 'MOST_RARE',
  EST_PROFIT: 'MAX_PROFIT',
  EST_RISKY: 'MOST_RISKY',

  EST_GOLD: 'PLACE_GOLD',
  EST_SILVER: 'PLACE_SILVER',
  EST_BRONZE: 'MOST_BRONZE',
};

export default class ControllerScoreTable {
  constructor() {
    this.resolve = () => {
    };
    this.element = document.getElementById('score');
    this.tbody = this.element.getElementsByTagName('tbody')[0];
    this.title = this.element.getElementsByClassName('title')[0];
    this.playersText = this.element.getElementsByClassName('players')[0];
    this.betText = this.element.getElementsByClassName('bet_column')[0];
    this.winText = this.element.getElementsByClassName('win_amount')[0];

    const localizationManager = window.OPWrapperService.localizations;
    this.title.innerText = localizationManager.getLocalizedText('score_title');
    this.playersText.innerText = localizationManager.getLocalizedText('players');
    this.betText.innerText = localizationManager.getLocalizedText('bet_column');
    this.winText.innerText = localizationManager.getLocalizedText('win_amount');
    this.element.addEventListener('transitionend', this._onTransitionEnd.bind(this));

    this.rows = {};
    const values = Object.values(eScoreType);
    for (let i = 0; i < values.length; i++) {
      const item = values[i];
      this.rows[item] = this.tbody.rows[i % 3];
      if (GameModel.decimal > 2) this.rows[item].setAttribute('data-font-size', 'small');
    }

    this.tbody.innerHTML = '';
    ManagerResult.controllerScore = this;
  }

  show(data) {
    this.data = data.params;
    this.element.style.setProperty('pointer-events', 'all');
    this.element.classList.remove('hide');
    return new Promise(resolve => this.resolve = resolve);
  }

  hide() {
    this.tbody.innerHTML = '';
    this.element.classList.add('hide');
    this.element.style.setProperty('pointer-events', 'none');
  }

  _animateScore() {
    if (!this.data) return;
    const awards = this._getAwards();

    let delay = 0;
    for (let i = 0; i < awards.length; i++) {
      const info = awards[i];
      const row = this.rows[info.type].cloneNode(true);
      if (StatModel.myBetsInfo.find((item) => item.betId === info.betId)) {
        row.classList.add('current');
      } else {
        row.classList.remove('current');
      }
      row.cells[0].children[1].innerText = `${info.clientId}`;
      row.cells[1].children[0].innerText = `${formatCurrency(info.betAmount, info.decimals, false)} ${info.currency}`;
      row.cells[2].children[0].innerText = `${formatCurrency(info.winAmount, info.decimals, false)} ${info.currency}`;
      setTimeout(() => {
        SoundManager.play(eSoundsType.EST_CLICK, 1);
        this.tbody.appendChild(row);
      }, delay);
      delay += 250;
    }
  }

  _getAwards() {
    const { awards } = this.data;

    return [...awards].sort((a, b) => {
      if (a.type === eScoreType.EST_PROFIT) return -1;
      if (a.type === eScoreType.EST_RARE) return 1;
      if (a.type === eScoreType.EST_RISKY && b.type === eScoreType.EST_RARE) return -1;
      if (a.type === eScoreType.EST_RISKY && b.type === eScoreType.EST_PROFIT) return 1;

      if (a.type === eScoreType.EST_GOLD) return -1;
      if (a.type === eScoreType.EST_BRONZE) return 1;
      if (a.type === eScoreType.EST_SILVER && b.type === eScoreType.EST_BRONZE) return -1;
      if (a.type === eScoreType.EST_SILVER && b.type === eScoreType.EST_GOLD) return 1;
    });
  }

  _onTransitionEnd() {
    if (this.element.classList.contains('hide')) {
      this.resolve();
      this.element.style.setProperty('pointer-events', 'none');
    } else {
      this._animateScore();
      setTimeout(() => this.hide(), 5000);
    }
  }
}
