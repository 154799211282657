import eAnimationType from './eAnimationType';
import * as PIXI from 'pixi.js';
import { Spine } from 'pixi-spine';


class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.EAT_FIGHTER, src: 'assets/animations/fighter/fighter.json' },
      { type: eAnimationType.EAT_SHOOTER, src: 'assets/animations/shooter/shooters_animations.json' },
      { type: eAnimationType.EAT_PARACHUTES, src: 'assets/animations/parachutes/parachutes.json' },
      { type: eAnimationType.EAT_BG_JETS, src: 'assets/animations/bg_jets/skeleton.json' },
    ];
  }

  addAssetsToBundle(bundleAssets) {
    for (let asset of this.animationsData) {
      bundleAssets[asset.type] = asset.src;
    }
  }

  createAnimation(type) {
    const resource = PIXI.Assets.get(type);
    return new Spine(resource.spineData);
  }
}

export default new AnimationsLoader();
