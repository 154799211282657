import SoundManager from '../soundManager/SoundManager';
import eSoundsType from '../preloaders/sounds/eSoundsType';

export default class ControllerSettings {
  constructor() {
    this.settingsElement = document.getElementById('settings-block');
    this.fullscreenButtonElement = this.settingsElement.querySelector('#fullscreen-button');
    if (!OPWrapperService.UserAgentDetector.isMobile.android.device) this.fullscreenButtonElement.remove();
    this.volumeButtonElement = this.settingsElement.querySelector('#volume-button');
    this.burgerElements = this.settingsElement.querySelectorAll('.burger');

    this.onBurgerClick = this.onBurgerClick.bind(this);
    this._updateVolumeButtonState();
    this.fullscreenButtonElement.addEventListener('click', this.onFullscreenButtonClick.bind(this));
    this.volumeButtonElement.addEventListener('click', this.onClickVolume.bind(this));

    for (const button of this.burgerElements) {
      button.addEventListener('click', this.onBurgerClick.bind(this));
    }
  }

  onBurgerClick() {
    SoundManager.play(eSoundsType.EST_CLICK, 1);
    window.OPWrapperService.toggleSidebar();
  }

  onFullscreenButtonClick() {
    SoundManager.play(eSoundsType.EST_CLICK, 1);
    OPWrapperService.Fullscreen.toggleFullscreen();
    if (OPWrapperService.Fullscreen.isFullscreen) {
      this.fullscreenButtonElement.classList.remove('off');
    } else {
      this.fullscreenButtonElement.classList.add('off');
    }
  }

  onClickVolume() {
    SoundManager.toggleMute();
    this._updateVolumeButtonState();
    SoundManager.play(eSoundsType.EST_CLICK, 1);
  }
  //
  // showSidebar() {
  //   this.settingsElement.classList.add('active');
  //   this.burgerButtonElement.classList.add('active');
  //   this.blocker = document.createElement('div');
  //   this.blocker.classList.add('mobile-panel-blocker');
  //   this.settingsElement.insertAdjacentElement('beforebegin', this.blocker);
  //   this.blocker.addEventListener('click', this.onBurgerClick);
  // }
  //
  // hideSidebar() {
  //   this.settingsElement.classList.remove('active');
  //   this.burgerButtonElement.classList.remove('active');
  //   if (this.blocker) {
  //     this.blocker.removeEventListener('click', this.onBurgerClick);
  //     this.blocker.remove();
  //   }
  // }

  _updateVolumeButtonState() {
    if (SoundManager.muted) {
      this.volumeButtonElement.classList.add('off');
    } else {
      this.volumeButtonElement.classList.remove('off');

    }
  }
}
