import BaseValueContainer, { eButtonType } from './BaseValueContainer';

export default class BetContainer extends BaseValueContainer {
  constructor(element, model) {
    super(element, model);
    this.amountInput.value = this.model.formatBetAmount;
    this.autoCheckbox.checked = this.model.autoBet;

    this.model.onChange.add(this.onBetModelChanged.bind(this));
  }

  onBetModelChanged() {
    this.autoCheckbox.checked = this.model.autoBet;
  }

  onInputBlur(event) {
    const value = parseFloat(event.target.value);
    if (value) {
      this.model.checkAndSetBetAmount(value);
    } else {
      this.amountInput.value = this.model.formatBetAmount;
    }
  }

  onCheckboxChanged(event) {
    super.onCheckboxChanged(event);
    this.model.autoBet = event.target.checked;
  }

  onPlusClicked() {
    super.onPlusClicked();
    this.model.increaseBet();
  }

  onMinusClicked() {
    super.onMinusClicked();
    this.model.decreaseBet();
  }

  onButtonPressed(type) {
    if (type === eButtonType.EBT_MINUS) {
      this.model.decreaseBet();
    } else {
      this.model.increaseBet();
    }
  }

  onModelChanged() {
    super.onModelChanged();
    this.amountInput.value = this.model.formatBetAmount;
  }

  onLocalizationChanged() {
    super.onLocalizationChanged();
    this.element.getElementsByClassName('rates-setting__title')[0].textContent = window.OPWrapperService.localizations.getLocalizedText('bet_column');
  }

  _disableCondition() {
    return super._disableCondition() || window.OPWrapperService.freeBetsController.isFirstFreeBet || window.OPWrapperService.freeBetsController.isActive || window.OPWrapperService.freeBetsController.data.status === window.OPWrapperService.freeBetsController.eFreeBetsStatusType.EFBST_ACTIVE;
  }
}
