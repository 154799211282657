import TweenMax from 'gsap';
import * as PIXI from 'pixi.js';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import eEventType from '../events/eEventType';
import ControllerWater from './elements/ControllerWater';
import ControllerSky from './elements/ControllerSky';
import ControllerBGObjects from './elements/ConotrollerBGObjects';
import ControllerFlags from './elements/ControllerFlags';
import ManagerResult from '../ManagerResult';
import eGameState from '../models/eGameState';
import GameModel from '../models/GameModel';
import ControllerFrontItems from './elements/ControllerFrontItems';
import ControllerBgJets from './elements/ControllerBgJets';
import ControllerClouds from './elements/ControllerClouds';
import ControllerProgress from '../ui/ControllerProgress';
import SoundManager from '../soundManager/SoundManager';
import eSoundsType from '../preloaders/sounds/eSoundsType';

export default class ControllerBG {
  constructor(mainContainer) {
    this.mainContainer = mainContainer;
    this.tweens = [];

    this.backContainer = new PIXI.Container();
    this.mainContainer.addChild(this.backContainer);
    const jetContainer = new PIXI.Container();
    jetContainer.name = 'jet_container';
    this.frontContainer = new PIXI.Container();

    this._controllersBgNames = [
      'controllerSky',
      'controllerWater',
      'controllerBGObjects',
      'controllerBgJets',
      'controllerClouds',
    ];

    this.controllerSky = new ControllerSky(this.backContainer);
    this.controllerClouds = new ControllerClouds(this.backContainer);
    this.controllerWater = new ControllerWater(this.backContainer, this.controllerSky.container.height);
    this.controllerBGObjects = new ControllerBGObjects(mainContainer);
    this.controllerFlags = new ControllerFlags(this.controllerBGObjects.container);
    this.controllerBgJets = new ControllerBgJets(mainContainer);
    this.controllerFrontItems = new ControllerFrontItems(this.frontContainer);

    this.controllerProgress = new ControllerProgress(mainContainer);

    this.mainContainer.addChild(jetContainer, this.frontContainer);

    this._buildBlocker();

    GlobalDispatcher.add(eWSEventId.EWEI_START, this.startAnimation, this);
    GlobalDispatcher.add(eWSEventId.EWEI_PROGRESS, this.syncAnimation, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);

    ManagerResult.controllerBG = this;

    if (GameModel.state === eGameState.EGS_PROGRESS) {
      this.setX(GameModel.currentX);
    }

    // this.mainContainer.scale.set(0.1)
    document.addEventListener('visibilitychange', this._onVisibilityChange.bind(this));
  }

  _onFinish(data) {
    if (data.params.reason === 'jackpot') {
      let iterator = 0;
      let x = GameModel.currentX;
      clearInterval(this.jackpotInterval);
      this.jackpotInterval = setInterval(() => {
        x += 12;
        console.log('Jackpot x: ', x);
        this.syncAnimation({ params: { x } });
        iterator ++;
        if(iterator === 130) {
          clearInterval(this.jackpotInterval);
          this.stopAnimation();
        }
      }, 100)
    } else {
      this.stopAnimation();
    }
  }

  _onVisibilityChange() {
    if(!this.jackpotInterval) return;
    clearInterval(this.jackpotInterval);
    this.stopAnimation();
  }

  startAnimation() {
    TweenMax.killTweensOf(this.blocker);
    TweenMax.killTweensOf(this.mainContainer.scale);
    this.blocker.alpha = 0;

    this.controllerFrontItems.startAnimation();
    let animationStarted = false;
    let timeScale = 1;
    const tween = TweenMax.to(this.frontContainer, 3.31 / timeScale, {
      x: -this.frontContainer.width,
      onUpdate: () => {
        if (this.frontContainer.x < -547 && !animationStarted) {
          const tween_0 = TweenMax.to(this.frontContainer, 1.5 / timeScale, { y: 800, ease: Power1.easeInOut });
          const tween_1 = TweenMax.to(this.backContainer, 3 / timeScale, { y: 75 });
          animationStarted = true;
          this.tweens.push(tween_0, tween_1);
        }
      },
      ease: Power1.easeInOut,
    });
    this.tweens.push(tween);
  }

  setX(value) {
    this._resetOnProgress();
    for (const name of this._controllersBgNames) {
      try {
        this[name].setX(value);
      } catch (e) {
        console.error(e);
        console.error(name)
      }

    }
    this.controllerFlags.reset();
  }

  syncAnimation(data) {
    for (const name of this._controllersBgNames) {

      try {
        this[name].syncAnimation(data);
      } catch (e) {
        console.error(e)
        console.error(name)
      }

    }
  }

  stopAnimation() {
    for (const tween of this.tweens) {
      tween.kill();
    }
    this.tweens = [];
    for (const name of this._controllersBgNames) {
      this[name].stopAnimation();
    }
    this.controllerFrontItems.stopAnimation();
  }

  resetPositions() {
    this.mainContainer.scale.set(0.245);
    // this.mainContainer.scale.set(0.1);
    this.backContainer.y = 25;
    this.frontContainer.x = 0;
    this.frontContainer.y = 0;
    this.controllerFlags.reset();
    for (const name of this._controllersBgNames) {
      this[name].reset();
    }
    this.controllerFrontItems.reset();
    GlobalDispatcher.dispatch(eEventType.EET_RESET);
  }

  animateStartWaiting() {
    this.controllerFrontItems.animateStartWaiting();
    TweenMax.to(this.mainContainer.scale, 3, {
      x: 1,
      y: 1,
      onComplete: () => {
        this.controllerProgress.show();
        GlobalDispatcher.dispatch(eEventType.EET_ANIMATE_START_WAITING);
      }
    });
  }

  _buildBlocker() {
    if (this.blocker)
      this.blocker.destroy();

    this.blocker = new PIXI.Graphics();
    this.blocker.beginFill('0x000000');
    this.blocker.drawRect(-10, 0, this.mainContainer.width, this.mainContainer.height);
    this.blocker.alpha = 0;
    this.mainContainer.addChildAt(this.blocker, this.mainContainer.children.length);
  }

  animateBlocker(alpha) {
    return new Promise(((resolve) => TweenMax.to(this.blocker, 0.5, { alpha, onComplete: resolve })))
  }

  _resetOnProgress() {
    TweenMax.killTweensOf(this.mainContainer);
    TweenMax.killTweensOf(this.mainContainer.scale);
    TweenMax.killTweensOf(this.frontContainer);
    TweenMax.killTweensOf(this.backContainer);
    this.frontContainer.x = -this.frontContainer.width;
    this.frontContainer.y = 800;
    this.backContainer.y = 75;
    this.mainContainer.scale.set(1);
    this.controllerProgress.hide();
  }

  resetWaitingBets() {
    TweenMax.killTweensOf(this.mainContainer);
    TweenMax.killTweensOf(this.mainContainer.scale);
    TweenMax.killTweensOf(this.frontContainer);
    TweenMax.killTweensOf(this.backContainer);
    TweenMax.killTweensOf(this.blocker);
    clearInterval(this.jackpotInterval);
    this.frontContainer.x = 0;
    this.frontContainer.y = 0;
    this.backContainer.y = 25;
    this.blocker.alpha = 0;
    this.mainContainer.scale.set(1);
    this.controllerFrontItems.resetWaitingBets();
    this.controllerProgress.show();
  }
}
