import GameScaleManager from '../GameScaleManager';

export default class AdaptiveController {
  constructor() {
    this._preferredFontSize = null;
    this._adaptiveElement = null;
    this._observer = null;

    this._adapt = this._adapt.bind(this);
  }

  _adapt() {
    // element font size must be declared in parent element to be able to reset font size for different orientations
    this._adaptiveElement.style.fontSize = 'inherit';
    const fontSize = getComputedStyle(this._adaptiveElement).fontSize;
    if (fontSize === '') {
      requestAnimationFrame(this._adapt);
    } else {
      this._preferredFontSize = parseFloat(fontSize);
      this._adaptiveElement.style.fontSize = fontSize;
      this._adaptFontSize(this._adaptiveElement, this._preferredFontSize);
    }
  }

  _adaptFontSize(el, preferredFontSize) {
    if (el._savedScrollWidth === el.scrollWidth && el._savedTextContent === el.textContent) return; //it is a safeguard against an endless loop
    // css overflow property must be specified on an element;

    let iterationCount = 0;
    while (el.offsetWidth === el.scrollWidth) {
      if (iterationCount >= 100) break; //it is a safeguard against an endless loop
      iterationCount++
      const fontSize = parseFloat(el.style.fontSize || getComputedStyle(el).fontSize); //getComputedStyle give incorrect fontSize for a Google Pixel device
      if (fontSize === preferredFontSize) break;
      el.style.fontSize = (fontSize + 1) + 'px';
    }
    while (el.offsetWidth < el.scrollWidth) {
      if (iterationCount >= 100) break; //it is a safeguard against an endless loop
      iterationCount++
      const fontSize = parseFloat(el.style.fontSize || getComputedStyle(el).fontSize); //getComputedStyle give incorrect fontSize for a Google Pixel device
      el.style.fontSize = (fontSize - 1) + 'px';
    }
    el._savedScrollWidth = el.scrollWidth;
    el._savedTextContent = el.textContent;
  }

  set adaptiveElement(el) {
    if (this._adaptiveElement) {
      GameScaleManager.onChangeOrientation.remove(this._adapt);
      this._observer.disconnect();
    }

    this._adaptiveElement = el;
    if (!this._adaptiveElement) return;

    this._preferredFontSize = parseFloat(getComputedStyle(el).fontSize);
    GameScaleManager.onChangeOrientation.add(this._adapt);

    if (!this._observer) {
      this._observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList' || mutation.type === 'characterData') this._adaptFontSize(mutation.target, this._preferredFontSize);
        });
      });
    }

    this._observer.observe(this._adaptiveElement, { childList: true, characterData: true });
  }
}
