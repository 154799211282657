import SoundManager from './soundManager/SoundManager';
import eSoundsType from './preloaders/sounds/eSoundsType';
import GlobalDispatcher from './events/GlobalDispatcher';
import eWSEventId from './api/eWSEventId';
import GameModel from './models/GameModel';
import eGameState from './models/eGameState';

export default new class ManagerSFX {
  init() {
    this.volume = 1;
    if (GameModel.state !== eGameState.EGS_RESULTS) {
      SoundManager.play(eSoundsType.EST_SFX_MAIN, this.volume, true, 'sfx');
    }

    GlobalDispatcher.add(eWSEventId.EWEI_START, this._onStart, this);
    GlobalDispatcher.add(eWSEventId.EWEI_SPAWN_CRATE, this._spawnCrate, this);
    GlobalDispatcher.add(eWSEventId.EWEI_DESTROY_CRATE, this._destroyCreate, this);
    GlobalDispatcher.add(eWSEventId.EWEI_TAKE, this._flag, this);
    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
  }

  _onStart() {
    SoundManager.play(eSoundsType.EST_SFX_MAIN, this.volume, true, 'sfx');
  }

  _spawnCrate(data) {
    SoundManager.stop(eSoundsType.EST_SFX_MAIN, 'sfx');
    SoundManager.play(eSoundsType.EST_SFX_LOUNGE, this.volume, true, 'sfx');
  }

  _destroyCreate(data) {
    SoundManager.stop(eSoundsType.EST_SFX_LOUNGE, 'sfx');
    SoundManager.play(eSoundsType.EST_SFX_MAIN, this.volume, true, 'sfx');
  }

  _flag(data) {
    if(data.params.requestId)
      SoundManager.play(eSoundsType.EST_EJECT_PLAYER, this.volume);
  }

  _onFinish(data) {
    SoundManager.stop(eSoundsType.EST_SFX_MAIN, 'sfx');
    SoundManager.stop(eSoundsType.EST_SFX_LOUNGE, 'sfx');
  }
}
