import ImagePreloader from '../preloaders/images/ImagePreloader';
import TweenMax, { Linear } from 'gsap';

export default class SmileView {
  constructor(root, x, y, type, nick) {
    this.container = new PIXI.Container();
    this.container.x = x;
    this.container.y = y;
    this.container.alpha = 0;
    this.image = ImagePreloader.createImage(type);
    this.text = new PIXI.Text(nick);
    this.text.y = this.image.height;
    // this.container.addChild(this.image, this.text);
    this.container.addChild(this.image);
    this.image.x = this.container.width / 2 - this.image.width / 2;
    root.addChild(this.container);

    const targetY = this.container.y - 650;
    let finishStarted = false;
    TweenMax.to(this.container, 0.2, {
      alpha: 1,
      onComplete: () => {
        TweenMax.to(this.container, 3 + Math.random(), {
          y: targetY,
          x: this.container.x - Math.random() * 200,
          ease: Linear.easeNone,
          onUpdate: () => {
            if (this.container.y - targetY < 300 && !finishStarted) {
              finishStarted = true;
              TweenMax.to(this.container, 1.5, {
                alpha: 0,
                onComplete: () => {
                  TweenMax.killTweensOf(this.container);
                  this.container.destroy();
                }
              })
            }
          }
        })
      }
    })
  }
}
