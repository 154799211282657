import ControllerBalance from './ControllerBalance';
import ControllerCounter from './ControllerCounter';
import BetBlock from './bet/BetBlock';
import GameModel from '../models/GameModel';
import ControllerCoefsHistory from './ControllerCoefsHistory';
import ControllerTable from './ControllerTable';
import ControllerScoreTable from './ControllerScoreTable';
import ControllerJackpot from './ControllerJackpot';
import ControllerMiniPreloader from './ControllerMiniPreloader';
import ControllerSmilesPanel from './ControllerSmilesPanel';
import ControllerSettings from './ControllerSettings';
import ControllerInfoPanel from './ControllerInfoPanel';
import GameScaleManager from '../GameScaleManager';
import BetModel from '../models/BetModel';
import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import eEventType from '../events/eEventType';
import ManagerResult from '../ManagerResult';

export default class ControllerUI {
  constructor() {
    new ControllerBalance();
    new ControllerJackpot();
    new ControllerCounter();
    new ControllerCoefsHistory();
    new ControllerTable();
    new ControllerScoreTable();
    new ControllerMiniPreloader();
    new ControllerSmilesPanel();
    new ControllerSettings();
    new ControllerInfoPanel();

    this.bets = [];
    for (let i = 0; i < GameModel.bets.length; i++) {
      this.bets.push(new BetBlock(i, GameModel.bets[i]));
    }

    this.root = document.getElementsByClassName('wrapper')[0];
    this.gameContainer = document.getElementById('container');

    ManagerResult.controllerUi = this;

    GlobalDispatcher.add(eWSEventId.EWEI_FINISH, this._onFinish, this);
    GlobalDispatcher.add(eEventType.EET_RESET, this.startWaiting, this);

    GameModel.onBetsChanged.add(this.onGameBetsChanged.bind(this));
    GameScaleManager.onResize.add(this._updateScale.bind(this));
    GameScaleManager.onChangeOrientation.add(this._onOrientationChange.bind(this));
  }

  onGameBetsChanged() {
    this.bets = this.bets.filter(item => {
      if (GameModel.bets.find(model => item.model === model)) {
        return true
      } else {
        item.destroy();
        return false;
      }
    })

    for (let i = 0; i < GameModel.bets.length; i++) {
      if (!this.bets.find(item => item.model === GameModel.bets[i])) this.bets.push(new BetBlock(i, GameModel.bets[i]));
    }
  }

  startWaiting() {
    this.gameContainer.classList.remove('jackpot');
  }

  _onFinish({ params }) {
    if (params.reason !== 'jackpot') return;
    this.gameContainer.classList.add('jackpot');
  }

  _onOrientationChange({ orientation }) {
    this._updateBets(orientation);
  }

  _updateBets(orientation) {
    if (orientation === 'H' && this.bets.length === 1) {
      GameModel.addBets(new BetModel());
    } else if (orientation === 'V' && this.bets.length === 2) {
      const inactiveBet = [ ...this.bets ].reverse().find(betBlock => !betBlock.model.roundID);
      if (inactiveBet) GameModel.removeBet(inactiveBet.model);
    }
  }

  _updateScale(data) {
    this._scaleData = data;
    const adapter = {
      H: Math.min(this._scaleData.innerWidth / this._scaleData.safeScreenWidth, this._scaleData.innerHeight / this._scaleData.safeScreenHeight),
      V: Math.min(this._scaleData.innerWidth / this._scaleData.safeScreenWidth, this._scaleData.innerHeight / this._scaleData.safeScreenHeight),
    };

    const uiScale = adapter[this._scaleData.orientation];

    const width = 100 * (1 / uiScale);
    const left = -(width - 100) / 2;
    const height = 100 * (1 / uiScale);
    const top = -(height - 100) / 2;

    Object.assign(this.root.style, {
      transform: `scale(${uiScale}) translateZ(0)`,
      width: `${width}%`,
      height: `${height}%`,
      left: `${left}%`,
      top: `${top}%`,
    });
  }
}
