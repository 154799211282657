import GameModel from '../models/GameModel';
import AdaptiveController from './adaptiveController';

export default class ControllerJackpot extends AdaptiveController {
  constructor() {
    super();
    this.label = document.getElementById('jackpotLabel');
    this.value = document.getElementById('jackpotValue');
    this.adaptiveElement = this.value;

    GameModel.onJackpotChanged.add(this._updateView.bind(this));
    this._updateView();
  }

  _updateView() {
    this.label.innerText = OPWrapperService.config.jackpotEnabled ?
      window.OPWrapperService.localizations.getLocalizedText('jackpot') : window.OPWrapperService.localizations.getLocalizedText('boss_reward');
    this.value.innerText = GameModel.formatJackpot;
  }
}
