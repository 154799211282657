export default {
  EST_CLICK: 'click',

  EST_CLEARED_FOR_TAKEOFF: 'cleared_for_takeoff',
  EST_CONNECTING_STRATOTANKER: 'connecting_stratotanker',
  EST_DEFLECTOR_OPEN: 'deflector_open',
  EST_EJECT_PLAYER: 'eject_player',
  EST_JACKPOT: 'jackpot',

  EST_JET_START: 'jet_start',
  EST_JET_DESTROYED: 'jet_destroyed',
  EST_JET_IDLE: 'jet_idle',
  EST_JET_IDLE2: 'jet_idle2',
  EST_JET_LIFTING: 'jet_lifting',
  EST_JET_REFUELING_FAIL: 'jet_refueling_fail',
  EST_JET_REFUELING_SUCCEED: 'jet_refueling_succeed',
  EST_JET_SUPERSONIC_IDLE: 'jet_supersonic_idle',
  EST_JET_SUPERSONIC_START: 'jet_supersonic_start',
  EST_JET_WAITING_IDLE: 'jet_waiting_idle',

  EST_JET_IDLE2_START: "jet_idle2_start",
  EST_JET_SUPERSONIC_TO_IDLE: "jet_supersonic_to_idle",
  EST_JET_START_FORSAGE: "jet_start_forsage",

  EST_JACKPOT_MUSIC: 'jackpot_music',

  // LC-78 Музыка для джекпота_1
  // LC-78 Музыка для джекпота
  EST_SFX_LOUNGE: 'sfx_lounge',
  EST_SFX_MAIN: 'sfx_main',

  EST_JACKPOT_START: 'jackpot_SFX',
  EST_JACKPOT_MISSED: 'jackpot_missed_SFX',
}
