import eImageType from '../../preloaders/images/eImageType';

export const eClusterType = {
  ECT_DAY: 'day',
  ECT_NIGHT: 'night',
  ECT_GREENERY: 'greenery',
  ECT_MOUNTS: 'mounts'
};

export const clustersInfo = [
  {
    obj: [
      {
        image: eImageType.EIT_BG_SHIP_0,
        x: 120,
        y: 100,
      },
      {
        image: eImageType.EIT_BG_SHIP_1,
        x: 1500,
        y: 110,
        scaleX: -1,
      },
    ]
  },
  {
    obj: [
      {
        image: eImageType.EIT_BG_SHIP_0,
        x: 120,
        y: 100,
      },
    ]
  },
  {
    obj: [
      {
        image: eImageType.EIT_BG_SHIP_1,
        x: 120,
        y: 110,
      },
    ]
  },
];
