import GameModel from '../models/GameModel';
import SoundManager from '../soundManager/SoundManager';

export default new class MessageAdapter {
  init() {
    const eventManager = window.OPWrapperService.eventManager;
    this.eventManager = eventManager;
    const receiveMessageTypes = eventManager.receiveMessageTypes;
    this.postMessagesTypes = eventManager.postMessageTypes;
    eventManager.add(receiveMessageTypes.RMT_UPDATE_BALANCE, this.updateBalance, this);
    eventManager.add(receiveMessageTypes.RMT_STOP_AUTO_SPINS, this.stopAutoSpins, this);
    eventManager.add(receiveMessageTypes.RMT_SET_AUDIO, this.setAudio, this);
    eventManager.add(receiveMessageTypes.RMT_SHOW_HELP, this.showHelp, this);
    eventManager.add(receiveMessageTypes.RMT_TOGGLE_PAYTABLE, this.togglePaytable, this);

    // storeObserver.addListener(selectors.getBetAmount, this.stakeUpdate.bind(this));
    // storeObserver.addListener(selectors.getGameState, this.stateUpdated.bind(this));

    this._roundStarted = false;

    // controllerSounds.onSoundSettingsCahnged.add(this.audioToggle.bind(this));
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {

  }

  stopAutoSpins() {
    for (const bet of GameModel.bets) {
      bet.autoBet = false;
    }
  }

  setAudio(boolean) {
    if (!SoundManager.muted === boolean) return;
    if (window.OPWrapperService.sideBar.ButtonSound && boolean) {
      SoundManager.unmuteAll();
      window.OPWrapperService.sideBar.ButtonSound.isMutedStyle = false;
    } else {
      SoundManager.muteAll();
      window.OPWrapperService.sideBar.ButtonSound.isMutedStyle = true;
    }
  }

  showHelp() {
    window.OPWrapperService.rulesWindow.show();
  }

  togglePaytable() {
    if (window.OPWrapperService.rulesWindow.isOpened) {
      window.OPWrapperService.rulesWindow.close();
    } else {
      window.OPWrapperService.rulesWindow.show();
    }
  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(progress) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_PROGRESS, { progress });
  }

  loadCompleted() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_COMPLETED, { betAmount: GameModel.bets[0].betAmount, currency: GameModel.currency, balance: GameModel.balance });
    // this.audioToggle();
  }

  audioToggle(muted) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, { audioState: !muted });
  }

  stateUpdated() {

  }

  trySendRoundStart() {
    if (!GameModel.bets.find(bet => bet.roundID) || this._roundStarted) return;
    this._roundStarted = true;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_START, {
      currency: GameModel.currency,
      balance: GameModel.balance
    });
  }

  trySendRoundEnd() {
    if (GameModel.bets.find(bet => bet.roundID) || !this._roundStarted) return;
    this._roundStarted = false;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_END, {
      currency: GameModel.currency,
      balance: GameModel.balance
    });
  }

  betChange(betAmount) {
    if (this._betAmount === betAmount) return;
    this._betAmount = betAmount;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_BET_CHANGE, { betAmount, currency: GameModel.currency });
    this.eventManager.dispatch(this.postMessagesTypes.PMT_STAKE_UPDATE, { betAmount, currency: GameModel.currency } );
  }
};
