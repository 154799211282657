import { SocketController } from './managerWS';
import eWSEventId from './eWSEventId';
import { getUrlParam } from '../utils/url';

export default new class ConnectionStressTesting {
  constructor() {
    this.clients = [];
  }

  makeConnections(number) {
    for (let i = 0; i < number; i++) {
      this._connect();
    }
  }

  makeBets(number) {
    if(!number || number > this.clients.length) number = this.clients.length;

    for (let i = 0; i < number; i++) {
      let client = this.clients[i];
      const requestTime = new Date();
      console.log()
      client.ws.send({ amount: 10, id: eWSEventId.EWEI_BET})
        .then((response) => {
          const responseTime = new Date();
          console.log('requestDelay: ', responseTime - requestTime)
          this.clients[i] = {...client, ...response};
        });
    }
  }

  takeBets(number) {
    if(!number || number > this.clients.length) number = this.clients.length;

    for (let i = 0; i < number; i++) {
      let client = this.clients[i];
      const requestTime = new Date();
      if(!client.betId) {
        console.log(client);
        continue;
      }
      client.ws.send({ betId: client.betId, id: eWSEventId.EWEI_TAKE})
        .then((response) => {
          const responseTime = new Date();
          console.log('requestDelay: ', responseTime - requestTime)
          // client = {...client, ...response};
        });
    }
  }

  cancelBets(number) {
    if(!number || number > this.clients.length) number = this.clients.length;

    for (let i = 0; i < number; i++) {
      let client = this.clients[i];
      const requestTime = new Date();
      if(!client.betId) {
        console.log(client);
        continue;
      }
      client.ws.send({ betId: client.betId, id: eWSEventId.EWEI_CANCEL})
        .then((response) => {
          const responseTime = new Date();
          console.log('requestDelay: ', responseTime - requestTime)
          // client = {...client, ...response};
        });
    }
  }

  _connect() {
    const xhr = new XMLHttpRequest();
    let prefix = '';
    if (/.stage/.test(getUrlParam('api'))) {
      prefix = 'stage.'
    } else if (/.dev/.test(getUrlParam('api'))) {
      prefix = 'dev.';
    }
    xhr.open('GET', `https://int.${prefix}onlyplay.net/integration/request_frame/?game=fighter&tp=1`, true);
    xhr.onload = () => {
      console.log(xhr.responseURL);
      let prefix = '';
      if (/.stage/.test(getUrlParam('api'))) {
        prefix = 'stage.'
      } else if (/.dev/.test(getUrlParam('api'))) {
        prefix = 'dev.';
      }
      const ws = new SocketController(`wss://fighter.${prefix}onlyplay.net/ws/fighter`, this.onMessage.bind(this));
      const URLParams = new URLSearchParams(xhr.responseURL.split('?')[1]);
      const sessionHash = URLParams.get('sid');
      const sessionId = parseInt(URLParams.get('gid'));
      ws.send({ id: 'auth', sessionId, sessionHash });
      const client = {
        ws,
      }
      this.clients.push(client);
    };
    xhr.send();
  }
}
