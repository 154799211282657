export default class ControllerTooltipMessage {
  constructor(element) {
    if (!element || element.length === 0) throw Error('Element can`t be null');
    this.element = element;
  }

  showMessage(desc) {
    this.element.classList.add('green');
    this._showMessage(desc);
  }

  showError(desc) {
    this.element.classList.remove('green');
    this._showMessage(desc);
  }

  _showMessage(desc) {
    if (this.timeout) clearTimeout(this.timeout);

    this.element.innerHTML = desc;

    this.element.classList.remove('hide');

    this.timeout = setTimeout(this.hide.bind(this), 2500);
  }

  hide() {
    this.element.classList.add('hide');
  }
}

