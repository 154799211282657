export const sleep = async (duration) => {
  return new Promise(r => setTimeout(r, duration));
};

export const waitForTransitionEnd = async (element) => {
  return new Promise((resolve) => {
    element.on('transitionend', (event) => {
      element.off('transitionend');
      resolve();
    });
  })
};

export const AsyncWithCancel = function (generator) {

  let cancel = false;
  this.call = async () => {
    let iterationCount = 0;
    while (true) {
      iterationCount++;
      const result = generator.next();
      await result.value;

      if (cancel || iterationCount > 1000 || result.done) {
        if(!result.done) console.log('Animation was interrupted on step', iterationCount);
        return;
      }
    }
  };

  this.cancel = () => {
    cancel = true;
  };
};
