import GlobalDispatcher from '../events/GlobalDispatcher';
import eWSEventId from '../api/eWSEventId';
import StatModel from '../models/StatModel';

export default class ControllerInfoPanel {
  constructor() {
    this.element = document.getElementById('info_panel');
    this.onlineLabel = document.getElementById('info_panel__online_label');
    this.onlineValue = document.getElementById('info_panel__online_value');
    this.betsLabel = document.getElementById('info_panel__bets_label');
    this.betsValue = document.getElementById('info_panel__bets_value');

    this.onlineLabel.innerText = `${window.OPWrapperService.localizations.getLocalizedText('online')}:`;
    this.betsLabel.innerText = `${window.OPWrapperService.localizations.getLocalizedText('bets_count')}:`;

    GlobalDispatcher.add(eWSEventId.EWEI_STATISTIC, this._update.bind(this));
    this._update({ params: StatModel.activityStats });
  }

  _update({ params }) {
    const { betsCount, online } = params;

    this.onlineValue.innerText = online;
    this.betsValue.innerText = betsCount;
  }
}
