import '../sass/styles.scss';
import GamePreloader from './gamePreloader/GamePreloader';
import * as PIXI from 'pixi.js';
require('pixi-spine');
import { Spine } from 'pixi-spine';

import ControllerBG from './bg/ControllerBG';
import AnimationLoader from './preloaders/animations/AnimationLoader';
import ControllerUI from './ui/ControllerUI';
import { requestAuth } from './api/wsRequests';
import ImagePreloader from './preloaders/images/ImagePreloader';
import OPWrapperService from './libs/op-wrapper-service';
import SoundsPreloader from './preloaders/sounds/SoundsPreloader';
import ManagerSFX from './ManagerSFX';
import SoundManager from './soundManager/SoundManager';
import ManagerResult from './ManagerResult';
import JetController from './jet/JetController';
import './utils/array';
import eSoundsType from './preloaders/sounds/eSoundsType';
import MessageAdapter from './messageAdapter/MessageAdapter';
import ControllerSmiles from './smiles/ControllerSmiles';
import GameScaleManager from './GameScaleManager';

import InterRegularWoff2 from '../assets/fonts/Inter/Inter-Regular.woff2';
import InterRegularWoff from '../assets/fonts/Inter/Inter-Regular.woff';
import InterRegularTtf from '../assets/fonts/Inter/Inter-Regular.ttf';

import InterSemiBoldWoff2 from '../assets/fonts/Inter/Inter-SemiBold.woff2';
import InterSemiBoldWoff from '../assets/fonts/Inter/Inter-SemiBold.woff';
import InterSemiBoldTtf from '../assets/fonts/Inter/Inter-SemiBold.ttf';

import InterBoldWoff2 from '../assets/fonts/Inter/Inter-Bold.woff2';
import InterBoldWoff from '../assets/fonts/Inter/Inter-Bold.woff';
import InterBoldTtf from '../assets/fonts/Inter/Inter-Bold.ttf';

import wrapperConfig from './wrapperConfig';
import { preloadFontFace } from './libs/op-wrapper-service/utils/preloadFontFace';
import { assetsBranding  } from '../branding/branding';

new OPWrapperService(wrapperConfig);

Spine.prototype.autoUpdateTransform = function () {
  if (Spine.globalAutoUpdate) {
    this.lastTime = this.lastTime || Date.now();
    var timeDelta = (Date.now() - this.lastTime) * 0.001;
    this.lastTime = Date.now();
    this.update(timeDelta);
  } else {
    this.lastTime = 0;
  }

  try {
    PIXI.Container.prototype.updateTransform.call(this);
  } catch (e) {
    // console.warn(e.message);
  }
};

PIXI.Container.prototype.updateTransform = function () {
  if (this.sortableChildren && this.sortDirty) {
    this.sortChildren();
  }
  this._boundsID++;
  this.transform.updateTransform(this.parent.transform);
  // TODO: check render flags, how to process stuff here
  this.worldAlpha = this.alpha * this.parent.worldAlpha;
  for (var i = 0, j = this.children.length; i < j; ++i) {
    var child = this.children[i];
    try {
      if (child.visible) {
        child.updateTransform();
      }
    } catch (e) {
      // console.warn(e.message);
    }
  }
};

window.testSounds = () => {
  // SoundManager.play(eSoundsType.EST_SUPERSONIC_START, 1, false, 'default', () => {
  //   SoundManager.play(eSoundsType.EST_SUPERSONIC_IDLE, 1, true);
  // })
  // setTimeout(() => SoundManager.play(eSoundsType.EST_SUPERSONIC_IDLE, 1, true), 1000);
  SoundManager.play(eSoundsType.EST_TEST, 1, true);
};
export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.configLoaded = false;
    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.soundsLoaded = false;
    this.animationsLoaded = false;
    this.gameInitilized = false;

    const multiplier = 1;
    this.config = {
      width: 1015 * multiplier,
      height: 650 * multiplier,
    };
    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    this.start();

    MessageAdapter.init();
  }

  start() {
    this.applicationOptions = {
      // antialias: true,
      // legacy: true,
      // backgroundColor: 0x6fc1f6,
      // transparent: true,
      width: this.config.width,
      height: this.config.height,
      resolution: this.isRetinaDisplay() ? 1 : 1,
      autoResize: true
    };
    this.app = new PIXI.Application(this.applicationOptions);
    this.container = document.getElementById('container');
    this.container.appendChild(this.app.view);
    this.app.view.style.setProperty('height', '100%');
    this.app.view.style.setProperty('width', '');

    this.miniPreloader = document.getElementById('miniPreloader');

    const bundleAssets = {};

    ImagePreloader.addAssetsToBundle(bundleAssets);
    AnimationLoader.addAssetsToBundle(bundleAssets);
    SoundsPreloader.addAssetsToBundle(bundleAssets);

    bundleAssets['GoldFont'] = 'assets/fonts/bitmap/goldFont.xml';

    assetsBranding(bundleAssets);

    PIXI.Assets.addBundle('assets', bundleAssets);

    Promise.allSettled([
      preloadFontFace('Inter', [InterRegularWoff2, InterRegularWoff, InterRegularTtf], 400),
      preloadFontFace('Inter', [InterSemiBoldWoff2, InterSemiBoldWoff, InterSemiBoldTtf], 600),
      preloadFontFace('Inter', [InterBoldWoff2, InterBoldWoff, InterBoldTtf], 700),
    ]).then(() => {
      console.log('Fonts loaded');
      this.fontsLoaded = true;
      this.tryInitGame();
    });

    requestAuth()
      .then(() => {
        console.log('Config loaded');
        this.configLoaded = true;
        this.tryInitGame();
      });
    PIXI.Assets.loadBundle('assets', this.onAssetsLoadingUpdate.bind(this))
      .then(this.onAssetsLoadingComplete.bind(this))
      .catch(this.onAssetsLoadingError.bind(this));
  }

  onAssetsLoadingError(event) {
    console.error(event);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Assets.reset();
  }

  onAssetsLoadingComplete() {
    console.log('Assets loaded');
    this.assetsLoaded = true;
    this.tryInitGame();
  }

  onAssetsLoadingUpdate(progress) {
    progress *= 100;
    OPPreloader.setProgress(progress);
    MessageAdapter.loadProgress(progress);
  }

  initScaleManager() {
    GameScaleManager.onChangeOrientation.add(({ orientation }) => {
      window.OPWrapperService.ControllerStatistic.setOrientation(orientation);
    });
    GameScaleManager.init(this.app);
  }

  tryInitGame() {
    if (this._readyForInit()) {
      this.initGame();
    }
  }

  get ready() {
    return this._readyForInit() && this.gameInitilized;
  }

  _readyForInit() {
    return (
      (
        this.assetsLoaded ||
        window.OPWrapperService.config.lightMode
      ) && this.fontsLoaded &&
      this.configLoaded
    );
  }

  initGame() {
    const gameWrapper = document.querySelector('.wrapper');
    gameWrapper.style.display = 'flex';
    new ControllerUI();

    MessageAdapter.loadCompleted();
    SoundManager.init();
    ManagerResult.init();
    this.gameInitilized = true;
    this.miniPreloader.style.setProperty('display', 'none');

    if (!window.OPWrapperService.config.lightMode) {
      const container = new PIXI.Container();
      // container.scale.set(0.245);
      // container.scale.set(0.1);
      container.pivot.set(3500, 1900);
      container.x = 860;
      container.y = 400;

      this.mainContainer = container;
      this.app.stage.addChild(this.mainContainer);
      window.controllerBG = new ControllerBG(this.mainContainer);

      ManagerSFX.init();
      new JetController(this.mainContainer);

      const smilesContainer = new PIXI.Container();
      this.app.stage.addChild(smilesContainer);
      new ControllerSmiles(smilesContainer);

      // window.OPPreloader.hide();

      // const test = AnimationLoader.createAnimation(eAnimationType.EAT_TEST);
      // test.state.setAnimation(0, 'animation', true);
      // test.x = 3130;
      // test.y = 1800;
      // this.mainContainer.addChild(test);
    } else {
      this.app.stop();
    }

    window.OPWrapperService.ControllerStatistic.watchClicks();
    this.initScaleManager();
    document.documentElement.style.setProperty('touch-action', 'pan-x pan-y');
    OPPreloader.hide().then(() => {
      window.OPWrapperService.freeBetsController.show();
    });
  }

  //todo: move to another place
  isRetinaDisplay() {
    let result = false;
    if (window.matchMedia) {
      let mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
      result = (mq && mq.matches || (window.devicePixelRatio > 1));
    }
    return result;
  }
}

window.addEventListener('load', () => {
  new Game();

  // active state for buttons on iOS
  //https://web.dev/add-touch-to-your-site/

  if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
    const elements = document.querySelectorAll('button');
    const emptyFunction = function () {};

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('touchstart', emptyFunction, false);
    }
  }
});

// OPPreloader.hide();
// const gameWrapper = document.querySelector('.wrapper');
// gameWrapper.style.display = 'block';
// GameScaleManager.init();

