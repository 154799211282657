import GameModel from '../models/GameModel';
import AdaptiveController from './adaptiveController';

export default class ControllerBalance extends AdaptiveController {
  constructor() {
    super();
    this.label = document.getElementById('balanceLabel');
    this.value = document.getElementById('balanceValue');
    this.adaptiveElement = this.value;

    GameModel.onBalanceChanged.add(this._updateView.bind(this));
    this._updateView();
  }

  _updateView() {
    this.label.innerText = window.OPWrapperService.localizations.getLocalizedText('balance', ['']);
    this.value.innerText = GameModel.formatBalance;
  }
}
