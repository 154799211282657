import * as PIXI from 'pixi.js';
import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import AnimationLoader from '../../preloaders/animations/AnimationLoader';
import eAnimationType from '../../preloaders/animations/eAnimationType';
import eJetAnimation from '../../jet/eJetAnimation';
import GlobalDispatcher from '../../events/GlobalDispatcher';
import eEventType from '../../events/eEventType';
import GameModel from '../../models/GameModel';
import eGameState from '../../models/eGameState';
import SoundManager from '../../soundManager/SoundManager';
import eSoundsType from '../../preloaders/sounds/eSoundsType';
import { getRandomInt } from '../../utils/random';

export default class ControllerFrontItems {
  constructor(container) {
    const shipContainer = new PIXI.Container();
    shipContainer.y = 1434;
    const stern = ImagePreloader.createImage(eImageType.EIT_SHIP_STERN);
    const bow = ImagePreloader.createImage(eImageType.EIT_SHIP_BOW);
    bow.y = 386;
    bow.x = stern.width;
    shipContainer.addChild(stern, bow);

    this.fighterLift = AnimationLoader.createAnimation(eAnimationType.EAT_FIGHTER);
    const animation = this.fighterLift.state.setAnimation(0, eJetAnimation.EJA_JET_LIFTING, false);
    animation.timeScale = 0;
    this.fighterLift.x = 2982;
    this.fighterLift.y = 1877;

    this.deflector = AnimationLoader.createAnimation(eAnimationType.EAT_FIGHTER);
    const animation_2 = this.deflector.state.setAnimation(0, eJetAnimation.EJA_DEFLECTOR_ON, false);
    animation_2.timeScale = 0;
    this.deflector.x = 2982;
    this.deflector.y = 1928;
    //shooter
    this.shooter = AnimationLoader.createAnimation(eAnimationType.EAT_SHOOTER);
    const animation_3 = this.shooter.state.setAnimation(0, 'shooter_start', false);
    animation_3.timeScale = 0;
    this.shooter.x = 3280;
    this.shooter.y = 1907;
    //random shooter
    this.danceIndex = 0;
    this.dancerAnimations = ['shooter_gangnam_style', 'shooter_gangnam_style2', 'shooter_hopak_style', 'shooter_jackson_style', 'shooter_yablochko_style']
    // this.dancerAnimations = ['shooter_jackson_style']
    this.shooterDancer = AnimationLoader.createAnimation(eAnimationType.EAT_SHOOTER);
    const randIndex = getRandomInt(this.dancerAnimations.length - 1);
    this.shooterDancer.state.setAnimation(0, this.dancerAnimations[randIndex], true);
    this.shooterDancer.x = 3260;
    this.shooterDancer.y = 1907;

    this.dust = AnimationLoader.createAnimation(eAnimationType.EAT_FIGHTER);
    const animation_5 = this.dust.state.setAnimation(0, eJetAnimation.EJA_DUST_JET_RUN, false);
    animation_5.timeScale = 0;
    this.dust.x = 2982;
    this.dust.y = 1877;

    container.addChild(this.shooter, this.shooterDancer, this.dust, this.deflector, shipContainer, this.fighterLift);

    if (GameModel.state === eGameState.EGS_RESULTS) {
      this.resetWaitingBets();
    }

    GlobalDispatcher.add(eEventType.EET_ANIMATE_DEFLECTOR_ON, this.animateDeflectorOn, this);
  }

  startAnimation() {
    this.shooter.state.setAnimation(0, 'shooter_end', false);
    this.dust.state.setAnimation(0, eJetAnimation.EJA_DUST_JET_RUN, false);
  }

  animateStartWaiting() {
    SoundManager.play(eSoundsType.EST_JET_LIFTING, 0.7);
    this.fighterLift.state.setAnimation(0, eJetAnimation.EJA_JET_LIFTING, false);

    this.shooter.state.setAnimation(0, 'shooter_start', false);
    this.shooter.state.addAnimation(0, 'shooter_idle', true, 0);
  }

  animateDeflectorOn() {
    this.deflector.state.setAnimation(0, eJetAnimation.EJA_DEFLECTOR_ON, false);
    SoundManager.play(eSoundsType.EST_DEFLECTOR_OPEN, 1, false, 'default', () => {
      SoundManager.play(eSoundsType.EST_CLEARED_FOR_TAKEOFF, 0.5);
    });
  }

  resetWaitingBets() {
    this.shooter.state.setAnimation(0, 'shooter_idle', true);
    this.deflector.state.setAnimation(0, eJetAnimation.EJA_DEFLECTOR_ON, false);
  }

  stopAnimation() {
    const animation2 = this.dust.state.setAnimation(0, eJetAnimation.EJA_DUST_JET_RUN, false);
    animation2.timeScale = 0;
  }

  reset() {
    const animation = this.deflector.state.setAnimation(0, eJetAnimation.EJA_DEFLECTOR_ON, false);
    animation.timeScale = 0;

    const animation1 = this.fighterLift.state.setAnimation(0, eJetAnimation.EJA_JET_LIFTING, false);
    animation1.timeScale = 0;

    const randIndex = getRandomInt(this.dancerAnimations.length - 1);
    this.shooterDancer.state.setAnimation(0, this.dancerAnimations[randIndex], true);
    this.danceIndex++;
  }
}
