import eErrorCodes from './eErrorCodes';

export default new class ManagerErrors {
  constructor() {
    // this.controllerTooltipErrors = new ControllerTooltipError(document.getElementById('tooltipErrorTop'));
  }

  handleError(error) {
    switch (error.code) {
      case eErrorCodes.EEC_INVALID_TAKE_BET_TIME:
      case eErrorCodes.EEC_TAKE_WITHOUT_BET:
      case eErrorCodes.EEC_OFTEN_EMOJI:
      case eErrorCodes.EEC_INVALID_TAKE_BET_TIME_OLD:
      case eErrorCodes.EEC_TAKE_WITHOUT_BET_OLD:
      case eErrorCodes.EEC_OFTEN_EMOJI_OLD:
        //do nothing
        break;
      case eErrorCodes.EEC_DISCONNECT:
        //todo: mb reconnect here
        break;
      case eErrorCodes.EEC_MAX_ONE_BET_IF_FREEBETS:
      case eErrorCodes.EEC_MAX_BET_LIMIT:
      case eErrorCodes.EEC_MAX_BET_LIMIT_OLD:
        const LocalizationManager = window.OPWrapperService.localizations;
        const titleLocalizationKey = 'OPWrapperService.errors.title';
        let title = LocalizationManager.getLocalizedText(titleLocalizationKey);
        const messageLocalizationKey = `OPWrapperService.errors.error_${error.code}`;
        let message = LocalizationManager.getLocalizedText(messageLocalizationKey);
        if (title === titleLocalizationKey || !title) {
          title = 'Error';
        }
        if ((message === messageLocalizationKey || !message)) {
          message = error.message ? error.message : 'Server error';
        }
        window.OPWrapperService.showMessage(`${title} (${error.code})`, message, true, false );
        break;

      default:
        window.OPWrapperService.showError(error.code, error.message);
        break;
    }
  }
}
