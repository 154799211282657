import ImagePreloader from '../../preloaders/images/ImagePreloader';
import eImageType from '../../preloaders/images/eImageType';
import ControllerMovingBGBase from './ControllerMovingBGBase';

export default class ControllerWater extends ControllerMovingBGBase {
  constructor(container, y) {
    super(container);
    this.mergeOffset = 600;
    this.stepDevider = 0.5;

    for (let i = 0; i < 6; i++) {
      const water = ImagePreloader.createImage(eImageType.EIT_WATER);
      water.x = -this.mergeOffset + i * 1200;
      water.y = y;
      this.container.addChild(water);
      if( i === 4) this.water = water;
      if( i === 5) this.water1 = water;
    }
  }

  setX(value) {
    super.setX(value);
    this.water.x = -this.container.x + this.zoomOffset - this.mergeOffset;
    this.water1.x = this.water.x + this.water.width - this.mergeOffset;
  }

  onUpdate() {
    super.onUpdate();
    const containerX = Math.round(this.container.x) - this.zoomOffset;
    if (containerX < -(this.water.x + this.water.width)) {
      this.water.x = this.water1.x + this.water1.width - this.mergeOffset;
      this.water.commingEmit = false;
    }
    if (containerX < -(this.water1.x + this.water1.width)) {
      this.water1.x = this.water.x + this.water.width - this.mergeOffset;
      this.water1.commingEmit = false;
    }
  }

  reset() {
    super.reset();
    this.water.x = -this.mergeOffset + 4 * 1200;
    this.water1.x = -this.mergeOffset + 5 * 1200;
  }
}
