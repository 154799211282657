export default {
  EJA_DEFLECTOR_OFF: 'DEW_deflector_off',
  EJA_DEFLECTOR_ON: 'DEW_deflector_on',
  EJA_DUST_JET_RUN: 'DEW_dust_jet_run',
  EJA_JET_CONNECTING_UNI: 'DEW_jet_connecting_uni',
  EJA_JET_DESTROYED: 'DEW_jet_destroyed',
  EJA_JET_IDLE: 'DEW_jet_idle',
  EJA_JET_IDLE2: 'DEW_jet_idle2',
  EJA_JET_IDLE_SUPERSONIC_IDLE: 'DEW_jet_idle_supersonic_idle',
  EJA_JET_IDLE_SUPERSONIC_START: 'DEW_jet_idle_supersonic_start',
  EJA_JET_IDLE_SUPERSONIC_TO_IDLE: 'DEW_jet_idle_supersonic_to_idle',
  EJA_JET_LIFTING: 'DEW_jet_lifting',
  EJA_JET_REFUELING_FAIL: 'DEW_jet_refueling_fail',
  EJA_JET_REFUELING_SUCCEED_20: 'DEW_jet_refueling_succeed_20',
  EJA_JET_REFUELING_SUCCEED_40: 'DEW_jet_refueling_succeed_40',
  EJA_JET_REFUELING_SUCCEED_60: 'DEW_jet_refueling_succeed_60',
  EJA_JET_REFUELING_IDLE: 'DEW_jet_connected_idle',
  EJA_JET_REFUELING_SUCCEED: 'DEW_jet_refueling_succeed',
  EJA_JET_START: 'DEW_jet_start',
  EJA_JET_START_IDLE: 'DEW_jet_start_idle',
  EJA_JET_START_RUN: 'DEW_jet_start_run',

  EJA_BOSS_REWARD: "dew_boss_reward",
  EJA_BOSS_REWARD_IDLE: "dew_boss_reward_idle",
  EJA_BOSS_REWARD_NOT_WIN: "dew_boss_reward_try_your_luck_again",
  EJA_BOSS_REWARD_NOT_WIN_IDLE: "dew_boss_reward_try_your_luck_again_idle",
  EJA_BOSS_REWARD_MISSED: "dew_missed_boss_reward",

  EJA_JACKPOT: "dew_jackpot",
  EJA_JACKPOT_IDLE: "dew_jackpot_idle",
  EJA_JACKPOT_NOT_WIN: "dew_jackpot_try_your_luck_again",
  EJA_JACKPOT_NOT_WIN_IDLE: "dew_jackpot_try_your_luck_again_idle",
  EJA_JACKPOT_MISSED: "dew_missed_jackpot",
}
